import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import Hero from "../components/Blog/Hero"
import ArticleList from "../components/Blog/ArticleList"
import PageContents from "../components/Blog/PageContents"
import { sortArticles } from "../services/blog/sort"

const BlogCategoryTemplate = (props) => {
  const { data, location } = props
  const path = location.pathname
  const { page, desktopArticles, mobileArticles, categories } = data
  const { hero, blocks } = page
  const category = page.title

  return (
    <Layout>
      <Seo
        title={page.metaTitle}
        description={page.metaDescription}
        path={path}
      />
      <Hero {...hero} />
      <ArticleList
        desktopArticles={sortArticles(desktopArticles.nodes, "categoryOrder")}
        mobileArticles={sortArticles(mobileArticles.nodes, "categoryOrder")}
        category={category}
        categories={categories.nodes}
      />
      {blocks && <PageContents blocks={blocks} />}
    </Layout>
  )
}

export default BlogCategoryTemplate

export const pageQuery = graphql`
  query BlogCategoryTemplateQuery($slug: String!, $mobileArticles: [String]) {
    page: contentfulCategory(slug: { eq: $slug }) {
      id
      slug
      title
      metaTitle
      metaDescription
      hero {
        header
        subText {
          raw
        }
        ctaLink
        ctaLabel
        hero {
          gatsbyImageData(width: 1920)
        }
        mobileHero {
          gatsbyImageData(width: 1202)
        }
      }
      blocks {
        __typename
        ... on ContentfulProductHighlightBlock {
          id
          ctaLabel
          introContent {
            raw
          }
          product {
            productSlug
          }
        }
        ... on ContentfulCarouselBlock {
          id
          carouselHeader {
            raw
          }
          ctaLabel
          ctaLink
          ctaPosition
          slides {
            __typename
            ... on ContentfulCategory {
              id
              slug
              title
              categoryColor
              shortDescription {
                childMarkdownRemark {
                  html
                }
              }
              thumbnail {
                gatsbyImageData(width: 716)
              }
            }
          }
        }
      }
    }
    categories: allContentfulCategory(sort: { fields: slug }) {
      nodes {
        categoryColor
        title
        slug
      }
    }
    desktopArticles: allContentfulArticle(
      filter: { category: { slug: { eq: $slug } } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        category {
          title
          slug
          categoryColor
        }
        categoryOrder
        tags
        slug
        title
        ...articleBodyFragment
        thumbnail {
          gatsbyImageData(width: 636, aspectRatio: 1)
        }
      }
    }
    mobileArticles: allContentfulArticle(
      filter: { slug: { in: $mobileArticles } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        category {
          title
          slug
          categoryColor
        }
        categoryOrder
        tags
        slug
        title
        ...articleBodyFragment
        thumbnail {
          gatsbyImageData(width: 636, aspectRatio: 1)
        }
      }
    }
  }
`
